.footer {
    background-color: #f8f8f8;
    font-size: 14px;
    padding: 65px 40px 40px;
}

@media screen and (min-width: 992px) {
    .disclaimer {
        font-size: 10px;

        & ul::after {
            content: '|';
            margin: 0 0.5rem;
        }
    }
}